<template>
  <a-form-model
    :model="query"
    class="search__form insturace__search__form"
    :label-col="{span:5}"
    :wrapper-col="{span: 18}"
    labelAlign="left"
  >
    <a-row>
      <a-col :span="8">
        <a-form-model-item label="项目部">
          <a-select
            :options="projectGroupList"
            v-model="query.projectDepartmentId"
            @change="handleGetProject"
            placeholder="项目部"
            allowClear
          ></a-select>
        </a-form-model-item>
      </a-col>
      <a-col :span="8">
        <a-form-model-item label="项目名称">
          <a-select
            :options="projectList"
            v-model="query.projectId"
            placeholder="项目名称"
            allowClear
            @change="handleGetSonAndPosi"
          ></a-select>
        </a-form-model-item>
      </a-col>
      <a-col :span="8">
        <a-form-model-item label="工种/专业">
          <a-select :options="positionList" v-model="query.positionId" placeholder="工种/专业" allowClear></a-select>
        </a-form-model-item>
      </a-col>
      <a-col :span="8">
        <a-form-model-item label="职位发布时间" >
          <a-date-picker v-model="query.publishDate" value-format="YYYY-MM-DD" />
        </a-form-model-item>
      </a-col>
      <a-col :span="8">
        <a-form-model-item label="保险状态" >
          <a-select
            v-model="query.insuranceStatus"
            placeholder="保险状态"
            style="width: 100%"
            allowClear
          >
            <a-select-option value="0">未购买</a-select-option>
            <a-select-option value="2">待付款</a-select-option>
            <a-select-option value="1">已购买未过期</a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>
      <a-col :span="8">
        <div class="form_btn_box" v-perms="'search'">
          <a-button type="primary" @click="refresh">查询</a-button>
          <a-button style="margin-left: 8px" @click="reset">重置</a-button>
        </div>
      </a-col>
    </a-row>
  </a-form-model>
</template>

<script>
import { getProjectGroup, getProject, getSonProject, getPosition } from '@/api/insurance'

export default {
  name: 'SearchForm',
  data () {
    return {
      query: {},
      projectGroupList: [],
      projectList: [],
      positionList: [],
      sonProjectList: []
    }
  },
  created () {
    getProjectGroup().then(res => {
      this.projectGroupList = res.data.map(item => ({
        ...item,
        value: item.projectDepartmentId,
        title: item.projectDepartment
      }))
    })
  },
  methods: {
    handleGetProject (value) {
      this.$set(this, 'query', {
        ...this.query,
        projectDepartmentId: value,
        projectId: undefined,
        positionId: undefined
      })
      this.projectList = []
      this.positionList = []
      if (value) {
        getProject(value).then(res => {
          this.projectList = res.data.map(item => ({
            ...item,
            value: item.projectId,
            title: item.projectName
          }))
        })
      }
    },
    handleGetSonAndPosi (value) {
      this.$set(this, 'query', {
        ...this.query,
        positionId: undefined
      })
      this.positionList = []
      if (value) {
        // getSonProject(value).then(res => {
        //   this.sonProjectList = res.data.map(item => ({
        //     ...item,
        //     value: item.demandId,
        //     title: item.wbProject
        //   }))
        // })
        getPosition(value).then(res => {
          this.positionList = res.data.map(item => ({
            ...item,
            value: item.positionId,
            title: item.position
          }))
        })
      }
    },
    reset () {
      this.$set(this, 'query', {})
    },
    refresh () {
      this.$emit('search')
    },
    getQuery () {
      return {
        ...this.query
      }
    }
  }
}
</script>
<style lang="less">
.insturace__search__form {
  .ant-form-item{
    margin-bottom: 8px;
  }
  .form_btn_box {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
